<div class="connect-digital-card">
	<div class="connect-digital-card-title" [ngSwitch]="planType">
		<ng-container *ngSwitchCase="PlanType.medical">{{ 'Unlock your medical ID card' | translate }}</ng-container>
		<ng-container *ngSwitchCase="PlanType.dental">{{ 'Unlock your dental ID card' | translate }}</ng-container>
		<ng-container *ngSwitchCase="PlanType.vision">{{ 'Unlock your vision ID card' | translate }}</ng-container>
	</div>
	<div class="connect-digital-card-subtitle">
		{{ 'Connect your insurance plan to unlock your digital ID cards' | translate }}
	</div>
	<div class="connect-digital-card-form" [formGroup]="form">
		<mat-form-field class="connect-digital-card-member-id-field">
			<mat-label>{{ 'Member ID' | translate }}</mat-label>
			<input #memberID matInput type="text" formControlName="memberId" autocomplete="off" />
		</mat-form-field>

		<div class="birthdate-form-container">
			<mat-label class="label">{{ 'Birth date' | translate }}*</mat-label>
			<div class="connect-digital-card-birth-date-fields">
				<mat-form-field>
					<input
						formControlName="month"
						matInput
						type="number"
						class="fs-unmask"
						placeholder="MM"
						autocomplete="off"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						formControlName="day"
						matInput
						type="number"
						class="fs-unmask"
						placeholder="DD"
						autocomplete="off"
					/>
				</mat-form-field>
				<mat-form-field>
					<input
						formControlName="year"
						matInput
						type="number"
						class="fs-unmask"
						placeholder="YYYY"
						autocomplete="off"
					/>
				</mat-form-field>
			</div>
		</div>

		<mat-form-field class="connect-digital-card-member-id-field">
			<mat-label>{{ 'Group Number' | translate }}</mat-label>
			<input #groupId matInput type="text" formControlName="groupId" autocomplete="off" />
		</mat-form-field>

		<mat-checkbox class="without-hover-ripple" (change)="onIsSocialSecurityNumberChange($event)" color="primary">
			{{ 'The Member Id is my Social Security Number' | translate }}
		</mat-checkbox>
	</div>
	<div class="connect-digital-card-button-wrapper">
		<ripple-button
			(click)="unlock()"
			[isLoading]="unlockLoadingHandler.isLoading$ | async"
			[isDisabled]="form.invalid"
			[label]="'Unlock' | translate"
		></ripple-button>
	</div>
</div>
