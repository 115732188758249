<div class="telehealth-medical-advice-container">
	<div class="content-wrapper">
		<div class="description-container">
			<img
				class="telehealth-img"
				src="/assets/images/telehealth-teaser/telehealth-teaser-mobile.png"
				alt="telehealth image"
			/>
			<div>
				<ng-container *ngIf="telehealthServiceFee$ | async as telehealthServiceFee">
					<div *ngIf="!telehealthServiceFee.tierPrice" class="title">{{ 'Free 24/7 Telehealth Support' | translate }}</div>
					<div *ngIf="telehealthServiceFee.tierPrice" class="title">{{ '24/7 Telehealth Support' | translate }}</div>
				</ng-container>
				<div class="subTitle">{{ 'Connect with doctors and mental health specialists' | translate }}</div>
				<ng-container *ngIf="telehealthServiceFee$ | async as telehealthServiceFee">
					<div *ngIf="telehealthServiceFee.tierPrice" class="telehealth-disclaimer">
						{{ 'Telehealth services through Doctegrity require a ${tierPrice} fee per session until your deductible is met' | translate: {
						tierPrice: telehealthServiceFee.tierPrice
					} }}
					</div>
				</ng-container>
			</div>
		</div>
		<div class="actions-wrapper">
			<ripple-button
				[isFullWidth]="isMobile$ | async"
				(onClick)="onTeaserClick('Doctor')"
				[hierarchy]="'tertiary'"
				[leadingIcon]="'video'"
				[label]="'Talk to a Doctor' | translate"
				class="action doctor-btn"
			></ripple-button>
		</div>
	</div>
</div>
