<mat-drawer-container class="sidebar-container" autosize>
	<mat-drawer
		class="drawer"
		*ngIf="!sidebarService.isHidden"
		[mode]="sidenavMode$ | async"
		[disableClose]="false"
		[opened]="sidebarService.isOpen"
		(openedChange)="notifySidebarService($event)"
	>
		<div
			(click)="sidebarService.toggle()"
			[matTooltip]="sidebarService.isFull ? ('Close Navigation' | translate) : ('Open Navigation' | translate)"
			matTooltipPosition="right"
			matTooltipClass="title-tooltip"
			class="sidebar-toggle-button"
		>
			<mat-icon class="icon-size-24 d-block">
				{{ sidebarService.isFull ? 'chevron_left' : 'chevron_right' }}
			</mat-icon>
		</div>

		<div [class.shrink]="sidebarService.isShrank" class="sidebar d-flex flex-direction-column">
			<div class="flex-grow-1">
				<div *ngIf="sidebarService.isShrank" class="d-flex justify-content-center pt-25 pb-100 cursor-pointer">
					<ng-container *ngIf="hasCustomPrimaryLogo$ | async; else fallback">
						<div class="d-flex flex-direction-column align-items-center">
							<img
								routerLink="/"
								alt="Logo"
								[src]="(appLogoUrl$ | async)?.primarySm"
								(error)="setDefaultImage()"
								class="mt-16 max-width-40"

							/>

							<img
								src="/assets/images/powered-by/powered-by-healthee-sm-vertical.png"
								alt="WLLogo"
								class="mt-16 width-40"
							/>
						</div>
					</ng-container>

					<ng-template #fallback>
						<img
							*ngIf="defaultLogo"
							routerLink="/" class="width-40"
							src="/assets/images/logo-v2/logo.svg" alt="Logo"
						/>

						<img
							*ngIf="!defaultLogo"
							routerLink="/" class="width-40"
							[src]="(appLogoUrl$ | async).primarySm" alt="Logo"
							(error)="setDefaultImage()"
						/>
					</ng-template>
				</div>
				<div
					*ngIf="!sidebarService.isShrank"
					[ngClass]="(isNewBrandLogoEnabled$ | async) ? 'logo-container-v2' : 'logo-container'"
				>
					<a href="/" class="logo">
						<ng-container *ngIf="hasCustomPrimaryLogo$ | async; else fallback">
							<div class="d-flex flex-direction-column align-items-center">
								<img
									alt="Logo"
									[src]="(appLogoUrl$ | async)?.primary"
									(error)="setDefaultImage()"
								/>

								<img
									src="/assets/images/powered-by/powered-by-healthee.png"
									alt="WLLogo"
									class="mt-16"
								/>
							</div>
						</ng-container>

						<ng-template #fallback>
							<img
								*ngIf="defaultLogo"
								alt="Logo"
								src="/assets/images/logo-v2/logo_text.svg" alt="Logo"
							/>
							<img
								*ngIf="!defaultLogo"
								[src]="(appLogoUrl$ | async).primary"
								alt="WLLogo"
								(error)="setDefaultImage()"
							/>
						</ng-template>
					</a>
				</div>

				<ul
					class="metismenu list-unstyled"
					id="side-menu"
					[ngClass]="{ 'side-menu-logo-v2': isNewBrandLogoEnabled$ | async }"
				>
					<ng-container *ngFor="let item of menuItems">
						<app-sidebar-item
							*ngIf="!item.hidden"
							[shrinked]="sidebarService.isShrank"
							[text]="item.label | translate : { _key: item.labelKey }"
							[icon]="item.icon"
							[selectedIcon]="item.selectedIcon"
							[link]="
								item.id === BenefitsMenuItem
									? (languageService.isSpanish$ | async) === false
										? '/coverage-info'
										: item.link
									: item.link
							"
							fragment="Menu"
							(click)="item.onClick()"
						>
						</app-sidebar-item>
					</ng-container>
				</ul>
				<div *ngIf="isChatAvailable$ | async">
					<div
						*ngIf="!sidebarService.isShrank"
						class="px-28 cursor-pointer mt-30"
						routerLink="/chat"
						routerLinkActive="chat-is-active"
						(click)="onClickChatWithZoe()"
					>
						<div
							class="px-8 py-7 border-border-color-light-blue radius-60 d-flex align-items-center justify-content-space-between"
						>
							<div class="d-flex align-items-center">
								<img
									src="/assets/icons/shared/zoe-icon-2025.svg"
									alt="Zoe Chat"
									class="zoe-icon icon-size-42 mr-10"
								/>
								<img
									src="/assets/icons/shared/zoe-icon-2025.svg"
									alt="Zoe Chat"
									class="zoe-icon active icon-size-42 mr-10"
								/>
								<span class="text-dark-blue font-size-14">{{ 'Chat with Zoe' | translate }}</span>
							</div>
							<mat-icon class="text-azure-radiance">chevron_right</mat-icon>
						</div>
					</div>
					<div
						*ngIf="sidebarService.isShrank"
						routerLink="/chat"
						routerLinkActive="chat-is-active"
						(click)="onClickChatWithZoe()"
						class="d-flex justify-content-center mt-35 cursor-pointer"
					>
						<img src="/assets/icons/shared/zoe-icon-2025.svg" alt="Zoe Chat" class="zoe-icon icon-size-42" />
						<img
							src="/assets/icons/shared/zoe-icon-2025.svg"
							alt="Zoe Chat"
							class="zoe-icon active icon-size-42"
						/>
					</div>
				</div>
				<app-plan-selection-button
					*ngIf="openEnrollmentAvailable$ | async"
					[shrink]="sidebarService.isShrank"
					(click)="defaultMenuItemOnClick()"
					source="menu"
				></app-plan-selection-button>
				<div *ngIf="!defaultLogo">
					<div
						*ngIf="!sidebarService.isShrank"
						class="secondary-logo-container"
					>
						<img
							[src]="(appLogoUrl$ | async).secondary"
							alt="WLLogo"
							(error)="setDefaultImage()"
						/>
						<img
							src="/assets/images/powered-by/powered-by-healthee.png"
							alt="WLLogo"
						/>
					</div>
					<div
						*ngIf="sidebarService.isShrank"
						class="secondary-logo-container-sm"					>
						<img
							[src]="(appLogoUrl$ | async).secondarySm"
							alt="WLLogo"
							(error)="setDefaultImage()"
							class="width-40"
						/>
						<img
							src="/assets/images/powered-by/powered-by-healthee-sm-vertical.png"
							alt="WLLogo"
							class="width-40"
						/>
					</div>

				</div>
			</div>

			<div *ngIf="!sidebarService.isShrank">
				<div class="app-version-wrapper text-center">
					<app-version-label></app-version-label>
				</div>

				<app-quantum-support *ngIf="showQuantumSupport"></app-quantum-support>
			</div>
		</div>
	</mat-drawer>

	<mat-drawer-content>
		<ng-content></ng-content>
	</mat-drawer-content>
</mat-drawer-container>
