<div class="debug-container">
	<div class="header">
		<img src="/assets/images/logo-v2/logo_text.svg">
		<h1 class="headline">Debug page</h1>
	</div>

	<div *ngIf="userData$ | async" class="user-info">
		Logged in as {{email}} <small>({{uid}})</small>.
	</div>


	<div *ngIf="storage$ | async" class="storage-container">
		<h2>Local Storage:</h2>
		<textarea class="keys">
		{{storage$ | async}}
		</textarea>
	</div>

	<ripple-button label="Clean & Logout" (click)="cleanup()"></ripple-button>
</div>
