import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs';
import * as Sentry from '@sentry/angular';

import { returnEnvironmentField } from '../utils/utils';

import { UserData } from '../models/user-data';

import { UserService } from './user.service';
import { UserPlanDataStoreService } from './stores/user-plan-data-store/user-plan-data-store.service';
import { AppInfo } from '@capacitor/app';
import { MobileAppService } from './mobile-app.service';
import { DeviceInfo } from '@capacitor/device';
import mixpanel from 'mixpanel-browser';
import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { isLocalEnv } from '../utils/is-local-env';
import { isE2eEnv } from '../utils/is-e2e-env';
import { captureExceptionSentry } from '../utils/sentry.utils';
import { PlanSelectionService } from './plan-selection.service';
import { Router } from '@angular/router';
import { SessionStorageService } from './sessionStorage.service';

const TAG = 'TrackingService';

export enum MixpanelProject {
	Healthee = 'Healthee',
	PCT = 'PCT',
}

@Injectable({
	providedIn: 'root',
})
export class TrackingService {
	private appInfo: AppInfo;
	private device: DeviceInfo;

	private debugMode: boolean;
	private isDisabled: boolean = false;
	private selectedPlanNames: { medical?: string; dental?: string; vision?: string };

	constructor(
		private userService: UserService,
		private mobileAppService: MobileAppService,
		private userPlanDataStoreService: UserPlanDataStoreService,
		private http: HttpClient,
		private planSelectionService: PlanSelectionService,
		private sessionStorageService: SessionStorageService,
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
	) {
		try {
			const windowObject = this.document.defaultView;
			this.isDisabled = isE2eEnv(windowObject) || isLocalEnv(windowObject);

			this.mobileAppService.ionicAppInfo$.subscribe({
				next: (data) => {
					if (data) {
						this.appInfo = data;
					}
				},
			});

			this.mobileAppService.deviceInfo$.subscribe({
				next: (data) => {
					if (data) {
						this.device = data;
					}
				},
			});

			this.planSelectionService.selectedPlans$.subscribe((plans) => {
				this.selectedPlanNames = {
					medical: plans?.medical?.contract.name,
					dental: plans?.dental?.contract.name,
					vision: plans?.vision?.contract.name,
				};
			});

			this.debugMode = returnEnvironmentField('debugAnalytics') === true || false;
			if (this.debugMode) {
				console.log('[TrackingService] starting in debug mode');
			}

			if (!this.isDisabled) {
				this.mixpanelInit();
			}
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('info');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	// ---- mixpanel region -----
	private mixpanelInit() {
		const { userPlatform, userDevice, userBrowser } = this.getUserDeviceAndPlatform(navigator.userAgent);
		mixpanel.init(environment.mixpanelProjectToken, {
			debug: this.debugMode,
			ignore_dnt: true,
		});

		mixpanel.init(
			environment.mixpanelProjectTokenPCT,
			{
				debug: this.debugMode,
				ignore_dnt: true,
			},
			MixpanelProject.PCT
		);
		mixpanel.register({
			build: this.appInfo?.build,
			version: this.appInfo?.version,
			user_agent: navigator.userAgent,
			platform: this.device?.platform,
			device_model: this.device?.model,

			// TODO: After updating to Capacitor 5, on iOS 16+, DeviceInfo.name will return a generic device name,
			// unless we add the appropriate entitlements: https://developer.apple.com/documentation/bundleresources/entitlements/com_apple_developer_device-information_user-assigned-device-name
			device_name: this.device?.name,
			device_OS_version: this.device?.osVersion,
			user_platform: userPlatform,
			user_device: userDevice,
			user_browser: userBrowser,
		});
		mixpanel.PCT.register({
			build: this.appInfo?.build,
			version: this.appInfo?.version,
			user_agent: navigator.userAgent,
			platform: this.device?.platform,
			device_model: this.device?.model,
			device_name: this.device?.name,
			device_OS_version: this.device?.osVersion,
			user_platform: userPlatform,
			user_device: userDevice,
			user_browser: userBrowser,
		});
	}

	private mixpanelUserIdentity(userData: UserData, medical: string, dental: string, vision: string) {
		mixpanel.identify(userData.uid);
		mixpanel.people.set({
			medical_plan: medical || '',
			dental_plan: dental || '',
			vision_plan: vision || '',
			user_id: userData.uid || '',
			externalId: userData.externalId || '',
			company_name: userData.companyName || '',
			birth_date: `${userData.birthday || ''}`,
			gender: userData.gender || '',
			preferredLanguage: userData.preferredLanguage || '',
		});

		mixpanel.PCT.identify(userData.uid);
		mixpanel.PCT.people.set({
			medical_plan: medical || '',
			dental_plan: dental || '',
			vision_plan: vision || '',
			user_id: userData.uid || '',
			externalId: userData.externalId || '',
			company_name: userData.companyName || '',
			birth_date: `${userData.birthday || ''}`,
			gender: userData.gender || '',
			preferredLanguage: userData.preferredLanguage || '',
		});
	}

	//$email and $name in userProperties are special properties used by mixpanel to identify users
	private mixpanelSendEvent(event: string, eventProperties: object = {}, project: MixpanelProject) {
		try {
			/*
                NOTE: adblockers may block browser events to mixpanel - consider using mixpanel proxy
                https://developer.mixpanel.com/docs/collection-via-a-proxy
            */
			if (project === MixpanelProject.PCT) {
				mixpanel.PCT.track(event, eventProperties);
			} else {
				mixpanel.track(event, eventProperties);
			}
		} catch (e) {
			console.warn(`[sendToMixpanel] Error sending data to mixpanel: ${e}`);
		}
	}

	// ---- end mixpanel region -----

	public identifyUser(userData: UserData = null) {
		try {
			return new Promise((resolve) => {
				if (this.isDisabled) return resolve(null);

				this.userPlanDataStoreService
					.get()
					.pipe(take(1))
					.subscribe((planData) => {
						const medical = planData?.contract?.name || '';
						const dental = planData?.dental?.name || '';
						const vision = planData?.vision?.name || '';

						if (userData) {
							if (this.debugMode) {
								console.log('[TrackingService.identifyUser]', {
									userData,
									medical,
									dental,
									vision,
								});
							} else {
								this.mixpanelUserIdentity(userData, medical, dental, vision);
								this.trackClientEvent('Session Initiated', {
									user_id: userData?.uid,
									url: this.getCurrentUrl(),
								});
							}
							resolve(null);
						} else {
							this.userService.userData$.pipe(take(1)).subscribe((data: UserData) => {
								if (this.debugMode) {
									console.log('[TrackingService.identifyUser]', data, medical, dental, vision);
								} else {
									this.mixpanelUserIdentity(data, medical, dental, vision);
									this.trackClientEvent('Session Initiated', {
										user_id: data?.uid,
										url: this.getCurrentUrl(),
									});
								}
								resolve(null);
							});
						}
					});
			});
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	private getCurrentUrl(): string {
		// Return the current URL if it exists, otherwise return the home URL
		// Incase we are after login this.router.getCurrentNavigation() doesn't exist yet.
		return this.router?.getCurrentNavigation()?.extractedUrl?.toString() || '/home';
	}

	private trackClientEventForProject(event: string, eventProperties: object = {}, project: MixpanelProject) {
		if (this.isDisabled) {
			console.info('[TrackingService.trackClientEvent.disabled]', project, event, eventProperties);
			return;
		}

		if (this.debugMode) {
			console.log('[TrackingService.trackClientEvent]', project, { event, ...eventProperties });
		} else {
			this.mixpanelSendEvent(event, eventProperties, project);
			this.sendToHealthee(event, eventProperties, project);
		}
	}

	public trackClientEvent(event: string, eventProperties: object = {}) {
		try {
			eventProperties = this.addAdditionalPropertiesToEventProperties(eventProperties);
			this.trackClientEventForProject(event, eventProperties, MixpanelProject.Healthee);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	public trackClientEventPCT(event: string, eventProperties: object = {}) {
		try {
			eventProperties = this.addAdditionalPropertiesToEventPropertiesPCT(eventProperties);
			this.trackClientEventForProject(event, eventProperties, MixpanelProject.PCT);
		} catch (err) {
			Sentry.withScope((scope) => {
				scope.setLevel('error');
				captureExceptionSentry(err, TAG);
			});
		}
	}

	private sendToHealthee(event: string, metaData: object, project: MixpanelProject) {
		try {
			const utmParams = this.sessionStorageService.getItem('utmParams');
			const metaDataWithUtm = { ...metaData, utmParams };
			const body = JSON.stringify({ ...metaDataWithUtm, event, project });
			const headers = new HttpHeaders().set('Content-Type', 'application/json');
			if (environment.clientLogApiUrl)
				this.http.post(environment.clientLogApiUrl, body, { headers: headers }).subscribe({
					error: (error) => {
						console.error(`[sendToHealthee] error with sending client log events: ${error}`, error);
					},
				});
		} catch (e) {
			console.log(`[sendToHealthee] Error sending data to healthee server: ${e}`);
		}
	}

	private addAdditionalPropertiesToEventProperties(eventProperties: object = {}) {
		const additionalProperties = {
			device: this.device?.model,
			platform: this.device?.platform,
			...eventProperties,
		};
		return additionalProperties;
	}
	private addAdditionalPropertiesToEventPropertiesPCT(eventProperties: object = {}) {
		const additionalProperties = {
			device: this.device?.model,
			platform: this.device?.platform,
			selectedPlans: {
				medical: this.selectedPlanNames?.medical,
				dental: this.selectedPlanNames?.dental,
				vision: this.selectedPlanNames?.vision,
			},
			...eventProperties,
		};
		return additionalProperties;
	}

	private getUserDeviceAndPlatform(userAgent) {
		userAgent = userAgent.toLowerCase();

		let userPlatform = '';
		if (userAgent.indexOf('windows') !== -1) {
			userPlatform = 'Windows';
		} else if (userAgent.indexOf('macintosh') !== -1 || userAgent.indexOf('mac os') !== -1) {
			userPlatform = 'Mac';
		} else if (userAgent.indexOf('linux') !== -1) {
			userPlatform = 'Linux';
		} else if (userAgent.indexOf('android') !== -1) {
			userPlatform = 'Android';
		} else if (
			userAgent.indexOf('iphone') !== -1 ||
			userAgent.indexOf('ipad') !== -1 ||
			userAgent.indexOf('ipod') !== -1
		) {
			userPlatform = 'iOS';
		} else {
			userPlatform = 'Unknown';
		}

		let userDevice = '';
		if (
			userAgent.indexOf('mobile') !== -1 ||
			userAgent.indexOf('android') !== -1 ||
			userAgent.indexOf('iphone') !== -1 ||
			userAgent.indexOf('ipad') !== -1 ||
			userAgent.indexOf('ipod') !== -1
		) {
			userDevice = 'Mobile';
		} else if (userAgent.indexOf('tablet') !== -1 || userAgent.indexOf('ipad') !== -1) {
			userDevice = 'Tablet';
		} else {
			userDevice = 'Desktop';
		}

		let userBrowser = '';
		if (userAgent.indexOf('firefox') !== -1) {
			userBrowser = 'Firefox';
		} else if (userAgent.indexOf('opr') !== -1 || userAgent.indexOf('opera') !== -1) {
			userBrowser = 'Opera';
		} else if (userAgent.indexOf('chrome') !== -1) {
			userBrowser = 'Chrome';
		} else if (userAgent.indexOf('safari') !== -1) {
			userBrowser = 'Safari';
		} else if (userAgent.indexOf('edge') !== -1) {
			userBrowser = 'Edge';
		} else if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
			userBrowser = 'Internet Explorer';
		} else {
			userBrowser = 'Unknown';
		}

		return { userPlatform, userDevice, userBrowser };
	}
}
