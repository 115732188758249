<ng-container *ngIf="{ urlProgress: urlProgress$ | async } as data" [ngSwitch]="data.urlProgress?.type">
	<ng-container
		*ngSwitchCase="HttpRequestProgressStatus.Success"
		[ngTemplateOutlet]="iframe"
		[ngTemplateOutletContext]="{ url: data.urlProgress.response }"
	></ng-container>
	<ng-container
		*ngSwitchCase="HttpRequestProgressStatus.Error"
		[ngTemplateOutlet]="error"
		[ngTemplateOutletContext]="{ error: data.urlProgress.response }"
	></ng-container>
	<ng-container *ngSwitchCase="HttpRequestProgressStatus.Loading" [ngTemplateOutlet]="loader"></ng-container>
</ng-container>

<ng-template #iframe let-url="url">
	<iframe [src]="url" width="100%" height="100%" allow="camera; microphone"></iframe>
</ng-template>

<ng-template #error let-error="error">
	<div class="req-status-container">
		<img src="assets/icons/round-factorial-mark.svg" />
		<h1>{{ TelehealthLocalize.iframeErrorMessageTitle }}</h1>
		<p>{{ TelehealthLocalize.iframeErrorMessageDescription }}</p>
		<button (click)="onReportAnIssue(error)" class="report-button healthee-btn sm tertiary">
			<ng-container
				*ngIf="reportAnIssueProgress$ | async as reportAnIssueProgress"
				[ngSwitch]="reportAnIssueProgress.type"
			>
				<span *ngSwitchDefault>{{ TelehealthLocalize.iframeErrorReportButton }}</span>
				<img
					*ngSwitchCase="HttpRequestProgressStatus.Success"
					src="/assets/images/icons/green-check-success.svg"
					alt="success"
				/>
				<app-loader *ngSwitchCase="HttpRequestProgressStatus.Loading" size="x-small"></app-loader>
			</ng-container>
		</button>
	</div>
</ng-template>

<ng-template #loader>
	<div class="req-status-container">
		<app-loader size="large"></app-loader>
	</div>
</ng-template>
