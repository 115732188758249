import { Observable } from 'rxjs';
import {Component} from '@angular/core';

import { UIService } from 'src/app/services/ui.service';
import { TrackingService } from 'src/app/services/tracking.service';
import { TelehealthService } from 'src/app/modules/telehealth/services/telehealth.service';
import {UserService} from "../../../../services/user.service";

@Component({
  selector: 'app-telehealth-teaser',
  templateUrl: './telehealth-teaser.component.html',
  styleUrls: ['./telehealth-teaser.component.scss']
})

export class TelehealthTeaserComponent {
	public isMobile$: Observable<boolean> = this.uiService.isMobile$;
	public telehealthServiceFee$ = this.userService.getTelehealthServiceFee();

	constructor(
		private telehealthService: TelehealthService,
		private trackingService: TrackingService,
		private uiService: UIService,
		private userService: UserService,
	) {
	}

	public onTeaserClick(buttonName): void {
		this.telehealthService.onOpenTelehealth();
		this.trackingService.trackClientEvent('Telehealth modal start', { Action: buttonName });
	}
}
