import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { talonBannerCard, telehealthBannerCard } from './non-eligible-layout.config';

import { TelehealthService } from '../../telehealth/services/telehealth.service';
import { AppManagerService } from '../../../services/app-manager.service';
import { TrackingService } from '../../../services/tracking.service';
import { UserService } from '../../../services/user.service';

import { BannerCard } from '../../standalone/banner-card/banner-card.component';

const JHS_COMPANY_NAME = 'JHS Community';
const CONGRUITY_COMPANY_SLUG = 'congruity';

@Component({
	selector: 'app-non-eligible-layout',
	templateUrl: './non-eligible-layout.component.html',
	styleUrls: ['./non-eligible-layout.component.scss'],
})
export class NonEligibleLayoutComponent implements OnInit, OnDestroy {
	public telehealthBannerCard: BannerCard = telehealthBannerCard;
	public talonBannerCard: BannerCard = talonBannerCard;
	public cards: BannerCard[] = [];

	private userDataSub: Subscription;

	constructor(
		private telehealthService: TelehealthService,
		private appManagerService: AppManagerService,
		private trackingService: TrackingService,
		private userService: UserService
	) {}

	ngOnInit(): void {
		this.setDisplayCards();
	}

	setDisplayCards() {
		this.talonBannerCard.action = () => {
			this.appManagerService.openTalonModal();
			this.trackingService.trackClientEvent('Talon modal started', {Source: 'Talon teaser'});
		};
		this.telehealthBannerCard.action = () => {
			this.telehealthService.onOpenTelehealth();
			this.trackingService.trackClientEvent('Telehealth modal started', {Source: 'Telehealth teaser'});
		};

		this.cards.push(this.telehealthBannerCard);
		console.log('non eligible layout')
		this.userDataSub = this.userService.userData$.subscribe(data => {
			if (this.userShouldSeeTalonBannerCard(data?.company))
			{
				this.cards.push(this.talonBannerCard);
			}
		});
	}

	userShouldSeeTalonBannerCard(company) {
		// Hide the care and cost (Cost Calculator) card for JHS and Congruity company users
		return (company?.name !== JHS_COMPANY_NAME &&
			company?.whiteLabel?.primaryLogoSlug !== CONGRUITY_COMPANY_SLUG
		);
    }


	ngOnDestroy(): void {
		this.userDataSub?.unsubscribe();
	}
}
