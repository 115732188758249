<!-- ionic-only action-bar. Not rendered on web version -->
<app-mobile-action-bar></app-mobile-action-bar>

<div
	class="global-container"
	*ngIf="{ open: showSidebar$ | async } as sidebarStatus"
	[ngClass]="sidebarStatus.open ? 'sidebar-open' : 'sidebar-close'"
>
	<div [@fade-in]="state" class="chat-container" [ngClass]="{ 'feed-deployed': featureFlag_chat_liveFeed }">
		<div class="chat-wrapper" [ngClass]="{ 'feed-open': isSuggestionsFeedOpen }">
			<ul #chatlistUL class="chat-msgs" *ngIf="conversation$ | async as conversation">
				<li *ngIf="isEnabledStagingBotConversation && desktopView && userRole === 'admin'">
					Watson Env Bot:
					<div class="bot-selection">
						<input
							type="radio"
							class="fs-unmask"
							name="bot-env"
							id="stagingRadioBtn"
							value="staging"
							(change)="onBotRBChange($event.target.value)"
						/>
						<label for="stagingRadioBtn">staging</label>
						<input
							type="radio"
							class="fs-unmask"
							name="bot-env"
							id="currEnvRadioBtn"
							value="{{ currEnv }}"
							[checked]="true"
							(change)="onBotRBChange($event.target.value)"
						/>
						<label for="currEnvRadioBtn">{{ currEnv }}</label>
					</div>
				</li>
				<li class="privacy">
					<span class="lock"></span>
					<span
						>{{ 'This chat is confidential, secure and encrypted.' | translate }}<br *ngIf="!mobileView" />
						{{ 'No one except for you has access to this data.' | translate }}</span
					>
				</li>
				<li class="chat-header">
					<div class="date">
						{{ 'Chat Started' | translate }} {{ conversation.chatStart | date : 'LL.dd.yyyy HH:mm a' }}
					</div>
				</li>
				<li
					[@in-out]="'in'"
					*ngFor="let message of conversation.messages; let len = count; index as i"
					[ngClass]="{ 'bot-wrapper': message.owner === 'bot', 'user-wrapper': message.owner === 'user' }"
				>
					<div *ngIf="message.owner === 'user'; else textTemplate" class="user msg-wrapper">
						<div class="user-bubble">
							<p class="msg" [innerHtml]="message.text"></p>
						</div>
						<div class="time">{{ message.created | date : 'h:mm a' }}</div>
					</div>
					<div *ngIf="message.type === 'avatar'" class="avatar msg-wrapper">
						<div class="content-wrapper coverage-wrapper">
							<img class="message-icon" src="../../../../../assets/icons/shared/zoe-icon-2025.svg" />

							<div
								class="coverage-bubble red"
								*ngIf="
									message?.coverageStatus === 'not_covered' &&
									(message.serviceType | planClassType | async) === PlanClassType.SBC
								"
							>
								{{ 'Summary Information Only' | translate }}
								<ng-template #tooltip>
									<div class="tooltip-title">
										{{ 'Limited information!' | translate }}
									</div>
									{{
										"This information is based on a summary outline of core services offered by your insurance company. Once I'll get more information from your insurance company, it will be available in the Healthee app. \n" +
											'Before visiting your providers, please coordinate with them on referral requirements, pre-authorizations, or any additional details they would require before seeing you.'
											| translate
									}}
								</ng-template>
								<div [appTooltip]="tooltip" [tooltipWidth]="'250px'" class="coverage-bubble-icon">
									<img src="/assets/icons/shared/question-circle.svg" alt="question" />
								</div>
							</div>

							<ng-container
								*ngIf="message.isCovered"
								[ngSwitch]="benefitWrapperComponent?.planClassType$ | async"
							>
								<div class="coverage-bubble red" *ngSwitchCase="PlanClassType.SBC">
									{{ 'Summary Information Only' | translate }}

									<ng-template #tooltip>
										<div class="tooltip-title">
											{{ 'Limited information!' | translate }}
										</div>
										{{
											"This information is based on a summary outline of core services offered by your insurance company. Once I'll get more information from your insurance company, it will be available in the Healthee app. \n" +
												'Before visiting your providers, please coordinate with them on referral requirements, pre-authorizations, or any additional details they would require before seeing you.'
												| translate
										}}
									</ng-template>
									<div [appTooltip]="tooltip" [tooltipWidth]="'250px'" class="coverage-bubble-icon">
										<img src="/assets/icons/shared/question-circle.svg" alt="question" />
									</div>
								</div>
								<div class="coverage-bubble" *ngSwitchDefault>
									{{ 'You have coverage!' | translate }}
								</div>
							</ng-container>
						</div>
					</div>
					<div *ngIf="message.type === 'ribbon_results'" class="ribbon-results msg-wrapper">
						<div class="content-wrapper">
							<app-ribbon-health-card-list
								[openAsDialog]="true"
								[rawRibbonCardData]="message.msg.ribbonProviders"
							>
							</app-ribbon-health-card-list>
						</div>
					</div>
					<ng-template #textTemplate>
						<div *ngIf="message.type === 'text'" [ngClass]="msgWrapperStyle(message)" class="msg-wrapper">
							<div class="content-wrapper">
								<p class="msg" [innerHtml]="message.text" (click)="processLinks($event)"></p>
							</div>
						</div>
					</ng-template>

					<div class="d-flex justify-content-center py-10" *ngIf="message.type === DateBadge">
						<div class="date-badge">{{ message.date }}</div>
					</div>

					<div *ngIf="message.type === 'option' && message.showCoverage" [ngClass]="message.owner">
						<div class="options-wrapper" [ngClass]="{ mobile: mobileView, desktop: !mobileView }">
							<button
								*ngFor="let option of message.options"
								class="options bt-gradient"
								[hidden]="!option.value.input.text"
								[ngClass]="option.state"
								(click)="chooseOption(option, message)"
								[innerHtml]="option.label || option.value.input.text"
							></button>
						</div>
					</div>
					<div *ngIf="message.type === 'disconnected_img'" [ngClass]="message.owner" class="custom-wrapper">
						<img
							[src]="
								mobileView
									? '/assets/images/chat_not_connected_talon_mobile.svg'
									: '/assets/images/chat_not_connected_talon.svg'
							"
							alt="disconnected"
						/>
					</div>

					<div
						*ngIf="message.type === 'get_data_medical' && message.showCoverage"
						[ngClass]="message.owner"
						class="custom-wrapper get-data-medical"
					>
						<div class="options-wrapper">
							<button
								class="options bt-gradient"
								(click)="chooseRelated(relatedCross, message.related)"
								*ngFor="let relatedCross of message.related"
								[ngClass]="relatedCross.state"
							>
								{{ relatedCross.title }}
							</button>
						</div>
					</div>
					<div *ngIf="message.type === 'coverage'" [ngClass]="message.owner" class="custom-wrapper">
						<app-benefit-wrapper [message]="message" [mobileView]="mobileView"> </app-benefit-wrapper>
					</div>
					<div *ngIf="message.type === 'change-location'" [ngClass]="message.owner" class="custom-wrapper">
						<div class="options-wrapper">
							<button
								class="options bt-gradient"
								(click)="openAddressBarDialog(message)"
								[disabled]="message.disable_location_picker"
							>
								{{ 'Change Location' | translate }}
							</button>
						</div>
					</div>
					<div *ngIf="message.type === 'get_data'" [ngClass]="message.owner" class="custom-wrapper">
						<div *ngIf="message.hasRibbonData" [ngClass]="{ mobile: mobileView, desktop: !mobileView }">
							<div>
								<h4>
									{{ 'Sure, lets find you a' | translate }}
									<span [innerHtml]="message.title"></span>.<br />
									{{ 'You can use the options below to get started.' | translate }}
								</h4>
								<div class="options-wrapper">
									<button
										class="options bt-gradient"
										(click)="showCoverage(message)"
										[disabled]="message.disable_show_coverage"
									>
										{{ 'Coverage Details' | translate }}
									</button>
									<button
										class="options bt-gradient"
										(click)="goToCareAndCostByService(message)"
										[disabled]="message.disable_location_picker"
									>
										{{ 'Find Providers' | translate }}
									</button>
								</div>
							</div>
						</div>
						<app-benefit-wrapper
							#benefitWrapper
							*ngIf="message.showCoverage || !message.hasRibbonData"
							[message]="message"
							[mobileView]="mobileView"
						>
						</app-benefit-wrapper>
						<div *ngFor="let note of message.notes" [hidden]="message.hasRibbonData" class="note-container">
							<div class="note-wrapper">
								<p [innerHtml]="note"></p>
							</div>
						</div>
						<div class="options-wrapper" [hidden]="message.hasRibbonData">
							<button
								*ngFor="let relatedCross of message.related"
								class="options bt-gradient"
								(click)="chooseRelated(relatedCross, message.related)"
								[ngClass]="relatedCross.state"
							>
								{{ relatedCross.title }}
							</button>
						</div>
					</div>
				</li>
				<li class="loading-wrapper" *ngIf="zoeWrites">
					<div class="bouncing-dot dot1"></div>
					<div class="bouncing-dot dot2"></div>
					<div class="bouncing-dot dot3"></div>
					<div class="text">
						{{ 'Zoe is typing' | translate }}
					</div>
				</li>

				<li>
					<!-- IONIC ANCHOR FOR SCROLLING TO BOTTOM OF PAGE. DO NOT DELETE -->
					<div #scrollerAnchor></div>
				</li>
			</ul>
		</div>
		<div *ngIf="featureFlag_chat_liveFeed && !desktopView && isSuggestionsFeedOpen" class="feed-wrapper">
			<app-suggestions-feed
				(sendToChatFromFeed)="sendToChatFromFeed($event)"
				(closeFeed)="closeFeed()"
				[isRollingPausedByChatFooter]="isRollingPausedByChatFooter"
			>
			</app-suggestions-feed>
		</div>
	</div>

	<div class="chat-footer">
		<img
			*ngIf="featureFlag_chat_liveFeed && !desktopView"
			class="feed-icon"
			src="{{ feedIcon }}"
			(click)="feedIconAction()"
		/>
		<div class="query-wrapper" [ngClass]="{ 'intercom-enabled': isIntercomEnabled }">
			<input
				#chatQueryInput
				[disabled]="zoeWrites || disableSend"
				[maxlength]="maxQueryLength"
				type="text"
				class="fs-unmask"
				[(ngModel)]="userQueryInput"
				placeholder="{{ 'Am I covered for...' | translate }}"
				(keyup.enter)="submitText()"
				(keydown.enter)="submitText()"
			/>

			<button class="submit-button" type="submit" [disabled]="zoeWrites" (click)="submitText()">
				<span>{{ 'Send' | translate }}</span>
				<img src="../../../assets/images/icons-ionic/chat-send.svg" />
			</button>

			<div class="chat-dialog" (click)="openChooseFromListDialog()">
				<span class="choose"></span>
			</div>
		</div>
	</div>

	<div *ngIf="featureFlag_chat_liveFeed && desktopView" class="feed-wrapper">
		<app-suggestions-feed (sendToChatFromFeed)="sendToChatFromFeed($event)"></app-suggestions-feed>
	</div>
</div>
