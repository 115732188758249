<div class="deductible-teaser-container">
	<div *ngIf="isDeductibleSyncing" class="loading-indicator-wrapper">
		<app-loader size="small"></app-loader>
	</div>
	<app-healthee-content-slider
		appearance="standard"
		[enableClickScroll]="false"
		[ngClass]="{ disabled: isDeductibleSyncing }"
	>
		<div
			class="deductibles-container"
			[class.desktop-unlock]="(isConnected$ | async) === false && (isMobile$ | async) === false"
		>
			<ng-container *ngIf="isConnected$ | async">
				<app-medical-spending-connected-deductible-card
					[hasMyselfBadge]="isIndividualData$ | async"
					[hasFamilyBadge]="(isIndividualData$ | async) === false"
					[outOfPocketSpend]="(outOfPocketDetail$ | async)?.inNetwork?.spend"
					[outOfPocketTotal]="(outOfPocketDetail$ | async)?.inNetwork?.maximum"
					[spend]="(deductibleDetailsCardData$ | async)?.inNetwork?.spend"
					[remaining]="(deductibleDetailsCardData$ | async)?.inNetwork?.remaining"
					[total]="(deductibleDetailsCardData$ | async)?.inNetwork?.maximum"
				></app-medical-spending-connected-deductible-card>

				<app-medical-spending-connected-deductible-card
					[hasMyselfBadge]="isIndividualData$ | async"
					[hasFamilyBadge]="(isIndividualData$ | async) === false"
					[isRed]="true"
					[outOfPocketSpend]="(outOfPocketDetail$ | async)?.outOfNetwork?.spend"
					[outOfPocketTotal]="(outOfPocketDetail$ | async)?.outOfNetwork?.maximum"
					[spend]="(deductibleDetailsCardData$ | async)?.outOfNetwork?.spend"
					[remaining]="(deductibleDetailsCardData$ | async)?.outOfNetwork?.remaining"
					[total]="(deductibleDetailsCardData$ | async)?.outOfNetwork?.maximum"
				></app-medical-spending-connected-deductible-card>
			</ng-container>

			<ng-container *ngIf="(isConnected$ | async) === false && (deductibles$ | async) as deductibles">
				<ng-container
					[ngTemplateOutlet]="deductible"
					[ngTemplateOutletContext]="{
						title: HomePageLocalize.deductibleInNetwork,
						value: deductibles.inNetwork,
						color: 'green'
					}"
				>
				</ng-container>
				<ng-container
					[ngTemplateOutlet]="deductible"
					[ngTemplateOutletContext]="{
						title: HomePageLocalize.deductibleOutOfNetwork,
						value: deductibles.outNetwork,
						color: 'red'
					}"
				>
				</ng-container>
			</ng-container>
		</div>
	</app-healthee-content-slider>

	<ng-container>
		<button
			*ngIf="
				(isConnected$ | async) === false &&
				((hasRibbonInsurancePartner$ | async) || (isTalonTpa$ | async)) &&
				(hasMedicalPlanByKayser$ | async) === false &&
				(isMobile$ | async) === false
			"
			(click)="openConnectionForm()"
			class="bt connect-button"
		>
			<mat-icon>lock_open</mat-icon>
			{{ 'Unlock full details' | translate }}
		</button>
	</ng-container>

	<ng-template #deductible let-Title="title" let-Value="value" let-Color="color">
		<div [class]="'deductible ' + Color">
			<div class="deductible-content">
				<div class="deductible-title">
					<div class="dot"></div>
					{{ Title | translate }}
				</div>
				<div class="deductible-value">
					<ng-container *ngIf="Value; else deductibleNotAvailable">
						{{ Value }}
					</ng-container>
				</div>
			</div>
			<svg class="deductible-bg" height="40" width="318">
				<circle r="504" cy="447%" cx="50%" />
			</svg>
			<ng-container>
				<button
					*ngIf="
						(isConnected$ | async) === false &&
						((hasRibbonInsurancePartner$ | async) || (isTalonTpa$ | async)) &&
						!(hasMedicalPlanByKayser$ | async) &&
						(isMobile$ | async)
					"
					(click)="openConnectionForm()"
					class="bt connect-button mobile"
				>
					<mat-icon>lock_open</mat-icon>
					{{ 'Unlock full details' | translate }}
				</button>
			</ng-container>
		</div>
	</ng-template>

	<ng-template #deductibleNotAvailable> N/A </ng-template>

	<ng-template #deductiblesForm>
		<div class="connect-form-modal">
			<p class="connect-form-description">
				{{ connectFormModalDescription }}
			</p>
			<app-connect-deductibles-form-handler
				(connected)="healtheeDialogService.close()"
			></app-connect-deductibles-form-handler>
		</div>
	</ng-template>
</div>
