import { Injectable } from '@angular/core';
import { AuthAction, Category, CustomUserAgentDetails, getAmplifyUserAgent, USER_AGENT_HEADER } from '@aws-amplify/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import CFG from '../config/app-config.json';
import { environment } from '../../environments/environment';

import { UserService } from './user.service';

export type CognitoTokens = { accessToken: string, refreshToken: string, idToken: string };
export type SSOLoginData = Record<string, string>;
export type ExtendedJwtPayload = JwtPayload & {
	dateCreated: string,
	userId: string,
	providerName: string,
	providerType: string,
	issuer: string,
	primary: string,
}

const OAUTH_PKCE_KEY_AMPLIFY_TAG = CFG.TAGS.oAuthCodeVerifierAmplityTag;
const OAUTH_PKCE_KEY_BACKUP_TAG = CFG.TAGS.oAuthCodeVerifierBackupTag;
const UNIFIED_SSO_AUTH_DATA_TAG = CFG.TAGS.unifiedSSOLoginDataTag;

@Injectable({ providedIn: 'root' })
export class MobileSSOService {
	constructor(private userService: UserService) {}

	public static backupOriginalOAuthPKCE() {
		// OAUTH_PKCE_KEY_AMPLIFY_TAG - PKCE code tag as saved by the amplify package
		const ouath_pkce_key = window.sessionStorage.getItem(OAUTH_PKCE_KEY_AMPLIFY_TAG);
		window.sessionStorage.removeItem(OAUTH_PKCE_KEY_AMPLIFY_TAG);
		localStorage.setItem(OAUTH_PKCE_KEY_BACKUP_TAG, ouath_pkce_key);
	}

	public static getBackupOAuthPKCE() {
		// PKCE code gets deleted after the SSO redirect, therefore needs to be backed-up
		const ouath_pkce_key = localStorage.getItem(OAUTH_PKCE_KEY_BACKUP_TAG);
		localStorage.removeItem(OAUTH_PKCE_KEY_BACKUP_TAG);
		return ouath_pkce_key;
	}

	public async saveSSOLoginData(code: string) {
		const tokens = await this.exchangeAuthCodeForCognitoTokens(code);
		this.saveCognitoTokensToLocalStorage(tokens);
	}

	public restoreSSOLoginData(): SSOLoginData {
		const ssoDataString = this.loadSavedSSOLoginDataFromLocalStorage();
		if (!ssoDataString) throw new Error('Invalid saved SAML login data');

		let ssoLoginData: SSOLoginData, idToken = null;

		try {
			ssoLoginData = JSON.parse(ssoDataString);
		} catch (error) {
			this.clearSSOLoginDataFromLocalStorage();
			throw new Error(error.message);
		}

		for (const key in ssoLoginData) {
			localStorage.setItem(key, ssoLoginData[key]);
			if (key.endsWith('idToken')) {
				idToken = ssoLoginData[key];
			}
		}

		if (idToken) {
			this.userService.updateToken(idToken);
		}

		return ssoLoginData;
	}

	private async exchangeAuthCodeForCognitoTokens(code: string): Promise<CognitoTokens> {
		const client_id = environment.cognito.defaultClient;
		const redirect_uri = environment.cognito.defaultOauth.redirectSignIn;
		const code_verifier = MobileSSOService.getBackupOAuthPKCE();

		const oAuthTokenEndpoint = 'https://' + environment.cognito.defaultOauth.domain + '/oauth2/token';

		const oAuthTokenBody = {
			grant_type: 'authorization_code',
			code,
			client_id,
			redirect_uri,
			...(code_verifier ? { code_verifier } : {}),
		};

		const body = Object.entries(oAuthTokenBody)
			.map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
			.join('&');

		const customUserAgentDetails: CustomUserAgentDetails = {
			category: Category.Auth,
			action: AuthAction.FederatedSignIn,
		};

		const { access_token, refresh_token, id_token, error } = await (
			(await fetch(oAuthTokenEndpoint, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					[USER_AGENT_HEADER]: getAmplifyUserAgent(customUserAgentDetails),
				},
				body,
			})) as any
		).json();

		if (error) {
			console.log('Error:', error);
			throw new Error(error);
		}

		return {
			accessToken: access_token,
			refreshToken: refresh_token,
			idToken: id_token,
		};
	}

	private saveCognitoTokensToLocalStorage({ accessToken, refreshToken, idToken }: CognitoTokens) {
		const decodedIdToken = jwtDecode(idToken) as ExtendedJwtPayload;

		if (!decodedIdToken) {
			console.log('Failed to extract userId and providerName from idToken.');
			throw new Error('Cannot get username and provider from idToken');
		}

		const { userId, providerName } = decodedIdToken;
		const clientId = environment.cognito.defaultClient;
		const user = `${providerName}_${userId}`;

		const ssoLoginData = {
			['CognitoIdentityServiceProvider.' + clientId + '.' + user + '.accessToken']: accessToken,
			['CognitoIdentityServiceProvider.' + clientId + '.' + user + '.idToken']: idToken,
			['CognitoIdentityServiceProvider.' + clientId + '.' + user + '.refreshToken']: refreshToken,
			['CognitoIdentityServiceProvider.' + clientId + '.' + user + '.clockDrift']: '0',
			['CognitoIdentityServiceProvider.' + clientId + '.LastAuthUser']: user
		};

		localStorage.setItem(UNIFIED_SSO_AUTH_DATA_TAG, JSON.stringify(ssoLoginData));
	}

	private loadSavedSSOLoginDataFromLocalStorage() {
		const ssoDataString = localStorage.getItem(UNIFIED_SSO_AUTH_DATA_TAG);
		return ssoDataString;
	}

	public clearSSOLoginDataFromLocalStorage() {
		localStorage.removeItem(UNIFIED_SSO_AUTH_DATA_TAG);
	}
}
