import { ProviderGender } from 'src/app/modules/main-layout/care-costs/helpers/providers.helpers';

export interface ZocdocInfo {
	data: ZocdocProvider;
	reasons: ZocdocReason[];
}

export interface ZocdocInfoResponse {
	data: ZocdocProviderResponse;
	reasons: ZocdocReason[];
}

export interface ZocdocReason {
	visitReasonId: string;
	name: string;
}

export interface ZocdocProviderResponse {
	npi?: string;
	first_name?: string;
	last_name?: string;
	title?: string;
	full_name?: string;
	gender_identity?: string;
	specialties?: string[];
	default_visit_reason_id?: string;
	locations?: ZocdocLocationResponse[];
	virtual_locations?: any[];
	practice?: any[];
}

export interface ZocdocProvider {
	npi?: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	fullName?: string;
	gender?: string; // gender_identity
	specialties?: string[];
	defaultVisitReasonId?: string;
	locations?: ZocdocLocation[];
	virtualLocations?: any[];
	practice?: any[];
}

export interface ZocdocLocationResponse {
	provider_location_id: string;
	accepts_patient_insurance: string;
	address1: string;
	address2?: string;
	city: string;
	state: string;
	zip_code: string;
	latitude: number;
	longitude: number;
	location_name?: string;
}

export interface ZocdocLocation {
	locationId: string;
	acceptsPatientInsurance: string;
	address1: string;
	address2?: string;
	city: string;
	state: string;
	zipCode: string;
	latitude: number;
	longitude: number;
	locationName?: string;
}

export interface ZocdocVirtualLocation {
	locationId: string;
	state: string;
	locationName?: string;
}

export const normalizeLocation = (location: ZocdocLocationResponse): ZocdocLocation => ({
	locationId: location.provider_location_id,
	acceptsPatientInsurance: location.accepts_patient_insurance,
	address1: location.address1,
	address2: location.address2,
	city: location.city,
	state: location.state,
	zipCode: location.zip_code,
	latitude: location.latitude,
	longitude: location.longitude,
	locationName: location.location_name,
});

export const normalizeVirtualLocation = (location: ZocdocLocationResponse): ZocdocVirtualLocation => ({
	locationId: location.provider_location_id,
	state: location.state,
	locationName: location.location_name,
});

export const normalizeZocdocProvider = (zocdocProvider: ZocdocProviderResponse): ZocdocProvider => ({
	npi: zocdocProvider.npi,
	firstName: zocdocProvider.first_name,
	lastName: zocdocProvider.last_name,
	title: zocdocProvider.title,
	fullName: zocdocProvider.full_name,
	gender: zocdocProvider.gender_identity.toLowerCase().charAt(0) as ProviderGender,
	specialties: zocdocProvider.specialties,
	defaultVisitReasonId: zocdocProvider.default_visit_reason_id,
	locations: (zocdocProvider.locations || []).map(normalizeLocation),
	virtualLocations: (zocdocProvider.virtual_locations || []).map(normalizeVirtualLocation),
	practice: zocdocProvider.practice,
});
