import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Capacitor } from '@capacitor/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';

import { useIonicFile, isIonic } from './utils/utils';
import { environment } from '../environments/environment';
import CFG from './config/app-config.json';

import { UIService } from './services/ui.service';
import { LanguageService } from './services/language.service';
import { MobileAppService } from './services/mobile-app.service';
import { StoreUpdateHandlerService } from './services/stores/store-update-handler.service';
import { PreviousRouteService } from './services/PreviousRouteService';
import { TrackingService } from './services/tracking.service';
import { LoggerService } from './services/logger.service';
import { AuthService } from './modules/account/login/auth.service';
import { IntercomService } from './services/intercom.service';
import { MobileSSOService } from './services/mobile-sso.service';

const CX_CLAIM_REVIEW_MESSAGE_ID_TAG = CFG.TAGS.cxClaimReviewDeepLinkMessageParameterTag;
const SSO_URL_REDIRECT_CODE_TAG = CFG.TAGS.ssoUrlRedirectCodeTag;
const SSO_URL_REDIRECT_STATE_TAG = CFG.TAGS.ssoUrlRedirectStateTag;

@Component({
	selector: 'app-root',
	templateUrl: useIonicFile('./app.component.html', './app.component.ionic.html'),
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public showScreenOverlay$: Observable<boolean>;
	public loaded: boolean = false;
	public routingSubscription: Subscription;

	constructor(
		private route: ActivatedRoute,
		private languageService: LanguageService,
		private router: Router,
		private uiService: UIService,
		private ngZone: NgZone,
		private mobileAppService: MobileAppService,
		private storeUpdateHandlerService: StoreUpdateHandlerService,
		private previousRouteService: PreviousRouteService,
		private trackingService: TrackingService,
		private logger: LoggerService,
		private intercomService: IntercomService,
		private mobileSSOService: MobileSSOService
	) {}

	ngOnInit() {
		this.logAngularAppLaunchTimeAfterUpdateThroughNativeLayer();

		if (window) window.name = 'healthee';
		if (isIonic()) {
			this.router.navigate(['/app-update']);
		}

		if (AuthService.isFederatedLogin) {
			this.uiService.setCognitoLoginMode();
		}

		this.intercomService.init();

		this.startAppInit();
	}

	private logAngularAppLaunchTimeAfterUpdateThroughNativeLayer() {
		const eventName = 'Angular app launch';
		const iOSLabelInTrackingService = 'iOS';
		const androidLabelInTrackingService = 'Android';

		const platform = Capacitor.getPlatform();
		let platformLabel: string;

		switch (platform) {
			case 'ios':
				platformLabel = iOSLabelInTrackingService;
				break;

			case 'android':
				platformLabel = androidLabelInTrackingService;
				break;

			default:
				return; // Not iOS nor Android, so no need to log app launch times
		}

		const eventProperties = {
			platform: platformLabel,
			type: 'Angular',
			environment: environment.envName,
		};

		this.trackingService.trackClientEvent(eventName, eventProperties);
	}

	private startAppInit() {
		this.waitForLocaleParam();
		this.registerObservables();
		this.setupAppUrlListener();
		this.registerMobileAppStatus();
		this.setScreenSize();
		this.storeUpdateHandlerService.init();
		this.subscribeToRouting();
	}

	private subscribeToRouting() {
		this.routingSubscription = this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.previousRouteService.setPreviousUrl(event.url);
			}
		});
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: UIEvent) {
		const targetedWindow = event.target as Window;
		this.uiService.updateAppViewMode(targetedWindow);
	}

	private waitForLocaleParam() {
		const subscription = this.route.queryParams.subscribe(async (params: Params) => {
			if (params.locale) {
				await this.languageService.setLocale(params.locale);
				this.loaded = true;
			}
		});
		setTimeout(() => {
			this.loaded = true;
			subscription.unsubscribe();
		}, 250);
	}

	private registerObservables() {
		this.showScreenOverlay$ = this.uiService.showScreenOverlay$;
	}

	private setupAppUrlListener() {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.ngZone.run(async () => {
				this.logger.hybridLog('App opened with URL:', event.url);
				this.copyCxDeepLinkParamsToLocalStorage(event.url);

				// Check for SSO redirect and auto-login if applicable
				const code = this.checkIfSSORedirectAndGetCode(event.url);
				if (code) return this.autoLoginUsingSSO(code);

				// All other deep-link app launches
				const launchRouteWithParams: string = event.url.includes('.com')
					? event.url.split('.com').pop()
					: event.url.split('.co').pop();

				this.logger.debugOnDevice(`Deep-link launch with route = '${launchRouteWithParams}'`);

				try {
					await this.launchAppFromUrl(launchRouteWithParams);
				} catch (_) {
					this.router.navigateByUrl('/');
				}
			});
		});
	}

	private copyCxDeepLinkParamsToLocalStorage(urlAsString: string) {
		const url: URL = new URL(urlAsString);
		const queryParams = new URLSearchParams(url.search);
		const cxMessageId = queryParams.get(CX_CLAIM_REVIEW_MESSAGE_ID_TAG);

		if (cxMessageId) {
			localStorage.setItem(CX_CLAIM_REVIEW_MESSAGE_ID_TAG, cxMessageId);
			this.logger.hybridLog(`[cxLinks] saved param [${CX_CLAIM_REVIEW_MESSAGE_ID_TAG}]="${cxMessageId}" to localstorage`);
		}
	}

	private checkIfSSORedirectAndGetCode(url: string) {
		const urlParams = new URLSearchParams(new URL(url).search);
		const code = urlParams.get(SSO_URL_REDIRECT_CODE_TAG);
		const state = urlParams.get(SSO_URL_REDIRECT_STATE_TAG);

		return code && state ? code : null;
	}

	public async autoLoginUsingSSO(code: string) {
		await this.mobileSSOService.saveSSOLoginData(code);
		this.redirectToLoginPageAndAutoLogin();
	}

	private redirectToLoginPageAndAutoLogin() {
		this.router.navigate(['/account/login'], {
			queryParams: { autoLogin: true },
		});
	}

	private async launchAppFromUrl(launchRouteWithParams: string) {
		if (this.isZocdocRedirectFlow(launchRouteWithParams)) {
			this.logger.hybridLog(`launchAppFromUrl zocdoc redirect flow`);

			await this.redirectWithRenamedParams(launchRouteWithParams);
			return;
		}

		this.logger.hybridLog(`launchAppFromUrl Should open app with route = '${launchRouteWithParams}'`);
		await this.router.navigateByUrl(launchRouteWithParams);
	}

	private isZocdocRedirectFlow(launchRouteWithParams: string): boolean {
		const urlObj = new URL(launchRouteWithParams, window.location.origin);
		const redirectQuerystringParams = urlObj.searchParams;

		return redirectQuerystringParams.has(CFG.TAGS.redirectedFromZocdocTag);
	}

	private async redirectWithRenamedParams(launchRouteWithParams: string) {
		const urlObj = new URL(launchRouteWithParams, window.location.origin);
		const redirectPath = urlObj.pathname; // only pathname without querystring params
		const launchQuerystringParams = urlObj.searchParams;
		const angularRouteParams = { ...this.route.snapshot.queryParams };

		this.logger.hybridLog(`zocDocRedirect: launchQuerystringParams: ${JSON.stringify(launchQuerystringParams, null, 2)}`);
		this.logger.hybridLog(`zocDocRedirect: angularRouteParams: ${JSON.stringify(angularRouteParams, null, 2)}`);

		// Add each querystring param that the app was launched with to the Angular params object
		launchQuerystringParams.forEach((value, key) => { angularRouteParams[key] = value });

		this.logger.hybridLog(`zocDocRedirect: launchQuerystringParams >> angularRouteParams: ${JSON.stringify(angularRouteParams, null, 2)}`);

		if (angularRouteParams[CFG.TAGS.zocdocOriginalCodeTag]) {
			this.logger.hybridLog(`zocDocRedirect: param '${CFG.TAGS.zocdocOriginalCodeTag}' should be renamed --> ${CFG.TAGS.zocdocCodeNicknameAtHealtheeTag}`);

			angularRouteParams[CFG.TAGS.zocdocCodeNicknameAtHealtheeTag] = angularRouteParams[CFG.TAGS.zocdocOriginalCodeTag];
			delete angularRouteParams[CFG.TAGS.zocdocOriginalCodeTag];
			delete angularRouteParams[CFG.TAGS.redirectedFromZocdocTag];
		}

		this.logger.hybridLog(`zocDocRedirect: should redirect to url = ${redirectPath},
			with updated angularRouteParams: ${JSON.stringify(angularRouteParams, null, 2) }`);

		await this.router.navigate([redirectPath], {
			queryParams: angularRouteParams,
			queryParamsHandling: 'merge'
		});
	}

	private registerMobileAppStatus() {
		App.addListener('appStateChange', ({ isActive }) => {
			this.mobileAppService.updateMobileAppStatus = isActive;
		});
	}

	private setScreenSize() {
		this.uiService.updateAppViewMode(window);
	}

	ngOnDestroy(): void {
		this.routingSubscription.unsubscribe();
	}
}
