<app-search-results-base-card [appearance]="appearance" [isFavorite]="isFavorite"
	(isFavoriteChange)="isFavoriteChange.emit($event)"
	>
	<div avatar></div>
	<ng-container title>
		<ng-container *ngIf="telehealthServiceFee$ | async as telehealthServiceFee">
			<ng-container *ngIf="!telehealthServiceFee.tierPrice">{{ 'Free Telehealth Visit' | translate }}</ng-container>
			<ng-container *ngIf="telehealthServiceFee.tierPrice">{{ '24/7 Telehealth visit' | translate }}</ng-container>
		</ng-container>
	</ng-container>
	<ng-container subTitle>
		{{category}}
	</ng-container>

	<div class="body" body>
		<div class="sub-body">
			<div class="item">
				<ripple-icon [name]="'camera'" [size]="rippleSize.size20"></ripple-icon>
				<span>{{ 'Online 24/7' | translate }}</span>
			</div>
			<div class="item">
				<ripple-icon [name]="'checkCircleBlue'" [size]="rippleSize.size20"></ripple-icon>
				<span>{{'Covered by Healthee' | translate}}</span>
			</div>
			<ng-container *ngIf="telehealthServiceFee$ | async as telehealthServiceFee">
				<div *ngIf="telehealthServiceFee.tierPrice" class="item">
					<ripple-icon [name]="'walletMoney'" [size]="rippleSize.size20"></ripple-icon>
					<span>{{ '${tierPrice} fee per session until your deductible is met.' | translate : {
						tierPrice: telehealthServiceFee.tierPrice,
					} }}</span>
				</div>
			</ng-container>
		</div>
	</div>

	<div footer class="footer">
		<div class="btns-wrapper">
			<button class="bt"
				[ngClass]="'secondary-blue'"
				(click)="openDetails()">
				{{ 'More info' | translate }}
			</button>
			<button class="bt"
				[ngClass]="'primary-blue'"
				(click)="openTelehealth()" >
				<ripple-icon name="videoWhite"></ripple-icon>
				{{ 'Connect Now' | translate }}
			</button>


		</div>
	</div>
</app-search-results-base-card>
