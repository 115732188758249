<ng-container [formGroup]="form">
	<mat-form-field>
		<label for="memberID">
			{{ 'Member ID' | translate }}
			<span class="required-asterisk">*</span>
		  </label>
		<input #memberID matInput type="text" formControlName="memberId" autocomplete="off" />
	</mat-form-field>

	<div class="birthdate-form-container">
		<div class="label">
			{{ 'Birth date' | translate }}
			<span class="required-asterisk">*</span>
		  </div>
		<div class="birth-date-fields">
			<mat-form-field>
				<input formControlName="month" matInput type="number" class="fs-unmask" placeholder="MM" autocomplete="off" />
			</mat-form-field>
			<mat-form-field>
				<input formControlName="day" matInput type="number" class="fs-unmask" placeholder="DD" autocomplete="off" />
			</mat-form-field>
			<mat-form-field>
				<input formControlName="year" matInput type="number" class="fs-unmask" placeholder="YYYY" autocomplete="off" />
			</mat-form-field>
		</div>
	</div>
	<mat-form-field>
		<label for="groupId">
			{{ 'Group Number' | translate }}
		</label>
		<input #groupId matInput type="text" formControlName="groupId" autocomplete="off" />
	</mat-form-field>
</ng-container>

<div class="connect-space">
	<app-loader *ngIf="saveLoadingHandler.isLoading$ | async"></app-loader>

	<ng-container *ngIf="(saveLoadingHandler.isLoading$ | async) === false">
		<button [disabled]="form.invalid" (click)="connect()" class="bt primary-blue">
			{{ 'Connect' | translate }}
		</button>
		<div *ngIf="!isTrinet" class="content-space-notes">
			{{ 'Having trouble connecting your plan?' | translate }}
			<span (click)="appManagerService.openContactUsModal()">{{ 'Contact us' | translate }}</span>
		</div>
	</ng-container>
</div>
