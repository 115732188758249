<div class="information-panel">
	<div class="information-panel-wrapper">
		<div class="information-icon">
			<ripple-icon [size]="ICON_SIZE.size40" name="laptop"></ripple-icon>
		</div>
		<div *ngIf="telehealthServiceFee$ | async as telehealthServiceFee" class="information-texts">
			<ng-container *ngIf="!telehealthServiceFee.tierPrice">
				<p class="highlighted">{{ 'You\'re eligible for free telehealth 24/7' | translate }}</p>
				<p class="d-flex align-items-center">
					<span>{{ 'Connect with a provider now' | translate }}</span>
					<span
						class="ml-4"
						[matTooltip]="'You are eligible for Free Telehealth 24/7, which provides you access to healthcare services remotely via phone or video call for free.' | translate"
					>
						<ripple-icon [name]="'infoCircle'" [size]="rippleSize.size16"></ripple-icon>
					</span>
				</p>
			</ng-container>

			<ng-container *ngIf="telehealthServiceFee.tierPrice">
				<p>{{ 'Telehealth 24/7' | translate }}</p>
				<p class="highlighted">{{ 'You’ll pay ${tierPrice} fee per session' | translate: {
					tierPrice: telehealthServiceFee.tierPrice
				} }}</p>
				<p class="d-flex align-items-center">
					<span>{{ 'You’ll pay until your deductible is met' | translate }}</span>
					<span
						class="ml-4"
						[matTooltip]="'You are eligible for 24/7 Telehealth with ${tierPrice} copay per session, which provides you access to healthcare services remotely via phone or video call.' | translate: {
							tierPrice: telehealthServiceFee.tierPrice
						}"
					>
						<ripple-icon [name]="'infoCircle'" [size]="rippleSize.size16"></ripple-icon>
					</span>
				</p>
			</ng-container>
		</div>
	</div>

	<div class="actions-wrapper">
		<ripple-button
			(onClick)="onTeaserClick('Doctor')"
			hierarchy="secondary"
			leadingIcon="video"
			[label]="'Talk to a Doctor' | translate"
			class="action doctor-btn"
		></ripple-button>
	</div>
</div>
