import { EnvironmentConfigurationInterface } from './environment.interface';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfigurationInterface = {
	production: false,
	environmentType: 'web',
	envName: 'dev',
	baseUrl: 'https://app.dev.healthee.team',
	domain: 'app.dev.healthee.team',
	trinetDomain: 'trinet.dev.healthee.team',
	unleashURL: 'https://ff-dev.healthee.co/proxy',
	language: 'en',
	intercomAppId: 'bazufyww',
	mixpanelProjectToken: '75ce5ed5247978f652dade78e50d9636',
	mixpanelProjectTokenPCT: 'c5da4b422bf1f14da3ce4f68f20fc050',
	clientLogApiUrl: 'https://logging-hooks.dev.healthee.team/app-logs',
	enableSentry: true,
	zocdocClientId: 'jadXb79dwZ5K3miaHr2ZyJ5yypxyKKaF',
	zocdocBaesUrl: 'https://auth-api-developer-sandbox.zocdoc.com/authorize',
	zocdocAudience: 'DeveloperApiPatient-Sandbox',
	pct2Link: 'https://pct.dev.healthee.team',

	serverPort: 443,

	/**
	 * Development only -
	 * @useAltServer - use to run nodejs server on a server other than the angular server
	 * @altServer - change to match the hostname and port of the machine running the nodejs server
	 */
	useAltServer: true,
	debugAnalytics: false,

	altServer: {
		hostname: 'https://api.dev.healthee.team',
		port: 443,
	},

	mpiServer: {
		hostname: 'https://mpi-api.dev.healthee.team',
		port: 443,
	},

	servicesSearchURL: 'https://search-service.dev.healthee.team/search/services',
	gipitpotURL: 'https://chat-api.dev.healthee.team/demo',

	authAdminApi: 'https://auth-admin-api.dev.healthee.team',
	cognito: {
		userPoolId: 'pFmJbZrsM',
		// healthee client
		defaultClient: '1ipqkn7hus3r8ctds7roblss95',
		cognitoDomain: 'auth.dev.healthee.team',
		hybridStorage: true,
		defaultOauth: {
			domain: 'auth.dev.healthee.team',
			scope: ['openid'],
			redirectSignIn: 'http://localhost:4200',
			redirectSignOut: 'http://localhost:4200',
			responseType: 'code',
		},
		clients: {
			trinet: {
				vendorPrettyName: 'TriNet',
				idpName: 'trinet2',
				appSubDomain: 'trinet',
				userPoolWebClientId: '13s02m0kmni17a5epc0uscjml7',
				oauth: {
					scope: ['openid'],
					responseType: 'code',
					redirectSignIn: 'https://trinet.dev.healthee.team/account/login',
					redirectSignOut: 'https://trinetqen2.hrpassport.com/',
				},
			},
			mpi: {
				vendorPrettyName: 'MPI',
				idpName: 'mpi',
				appSubDomain: 'mpi-app',
				userPoolWebClientId: '3moitt6tmaui28ahmnh3lra27v',
				sessionStorage: true,
				oauth: {
					scope: ['openid'],
					responseType: 'token',
					redirectSignIn: 'https://app.dev.healthee.team/account/login',
					redirectSignOut: 'http://localhost:4200',
				},
			},
		},
	},
	recaptcha: {
		siteKey: '6LfAeQcqAAAAAD9ehuVJxcVdLPkwosEFvLjjdzg0',
	},
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
