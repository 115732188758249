import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TxNativeModule } from '@transifex/angular';

import { DebugComponent } from './debug-page/debug.component';
import { RippleModule } from 'ripple';

@NgModule({
	declarations: [DebugComponent],
	imports: [CommonModule, TxNativeModule, RippleModule],
})
export class DebugModule {}
