import { Injectable } from '@angular/core';
import { Store } from '../../../utils/store/store';
import { MeUser } from '../../api/domain/me-user';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';
import { Maybe } from '../../../utils/types/maybe';
import { UserApiService } from '../../api/user-api/user-api.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { isNil } from '../../../utils/is/is-nil';
import { PlanType } from '../../../models/plan-type.model';
import { UserCoverageTier } from '../../api/domain/user-coverage-tier';
import { RxjsUtils } from 'src/app/utils/rxjs';
import { GroupIdFieldNamesPerPlanType } from '../../api/domain/group-id-field-names-per-plan-type';

@Injectable({
	providedIn: 'root',
})
export class MeUserStoreService extends Store<void, MeUser> {
	private isTokenReady$ = new BehaviorSubject<boolean>(false);
	constructor(private userApiService: UserApiService) {
		super();
	}

	protected retrieve(): Observable<Maybe<MeUser>> {
		return this.isTokenReady$.pipe(
			filter((ready) => ready),
			switchMap(() => this.userApiService.me())
		);
	}

	public setTokenReady(isReady: boolean): void {
		this.isTokenReady$.next(isReady);
	}

	hasConnectedContract(contractType: 'medical' | 'vision' | 'dental'): Observable<boolean> {
		return this.get().pipe(
			map((me) => (isNil(me) ? false : !isNil(me?.contractMemberIds[contractType]?.memberId))),
			shareReplay(1)
		);
	}

	contractMemberID(contractType: PlanType): Observable<string> {
		return this.get().pipe(
			map((me) => (isNil(me) ? null : me?.contractMemberIds[contractType]?.memberId)),
			shareReplay(1)
		);
	}

	getGroupIdForPlanType(contractType: PlanType): Observable<string> {
		return this.get().pipe(
			map((me) => {
				if (isNil(me)) {
					return null;
				}

				return me[GroupIdFieldNamesPerPlanType[contractType]] as string ?? null;
			}),
			shareReplay(1)
		);
	}

	getUserCoverageTier(): Observable<UserCoverageTier> {
		return this.get().pipe(
			RxjsUtils.isNotNil(),
			map((me) => ({
				medicalPlanTier: me.medicalPlanTier,
				dentalPlanTier: me.dentalPlanTier,
				visionPlanTier: me.visionPlanTier,
			}),
			shareReplay(1)
		));
	}

	isTalonTpa() {
		return this.get().pipe(
			map((me) => (isNil(me) ? false : me?.company?.isTalonTpa)),
			shareReplay(1)
		);
	}
}
