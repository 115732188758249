import { PlanType } from '../../../models/plan-type.model';
import { MeUser } from './me-user';

export type GroupIdFieldNames = Extract<keyof MeUser, 'groupId' | 'dentalGroupId' | 'visionGroupId'>;

export const GroupIdFieldNamesPerPlanType: Record<PlanType, GroupIdFieldNames> = {
	[PlanType.medical]: 'groupId',
	[PlanType.dental]: 'dentalGroupId',
	[PlanType.vision]: 'visionGroupId',
};
