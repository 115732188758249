import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild, AfterViewChecked } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { T } from '@transifex/angular';

import { ImmediateCareObj } from '../immediate-care.localize';
import { AdditionalNetwork, Benefit } from '../../../models/benefits.model';
import { PremiumFeature } from '../../../models/premium-feature.model';
import { SearchEntity } from '../../main-layout/care-costs/helpers/providers-search.helper';
import { networkStructureSupportsAdditionalNetworks } from '../immediate-care.helper';

import { UIService } from '../../../services/ui.service';
import { TrackingService } from '../../../services/tracking.service';
import { TelehealthService } from '../../telehealth/services/telehealth.service';
import { ProvidersSearchService } from '../../../services/providers-search/providers-search.service';
import { PremiumFeatureService } from '../../../services/premium-feature.service';
import { UnleashService } from 'src/app/services/unleash.service';
import {UserService} from "../../../services/user.service";

const IN_NETWORK_NAME = 'in-network';
const NO_NETWORK_NAME = 'no network';
const TWO_LINES_HIGHT = 50;
const NETWORK_NAME_CHECK_FLAG = 'mariamarabidze-tec-252-ha-health-transformation-alliance-immediate-care-coverage';

@Component({
	selector: 'app-immediate-care-card',
	templateUrl: './immediate-care-card.component.html',
	styleUrls: ['./immediate-care-card.component.scss'],
})
export class ImmediateCareCardComponent implements AfterViewChecked {
	@Input() immediateCareObj: ImmediateCareObj;
	@ViewChild('benefitDescription') benefitDescription: ElementRef;

	public expended: boolean = false;
	public showMoreTextPanel: boolean = false;
	public isMobile: boolean = UIService.isMobileDeviceScreen(window);
	public showTelehealthTeaser$: Observable<boolean> = this.premiumFeatureService.isEnabled(PremiumFeature.Telehealth);
	public isEnabled: boolean = this.unleashService.isEnabled(NETWORK_NAME_CHECK_FLAG);
	public telehealthServiceFee$ = this.userService.getTelehealthServiceFee();

	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may  incur additional costs."
	)
	public CopaySubjectToDeductibleIn: string;
	@T(
		"This is the fixed amount you'll pay out-of-pocket for this service, regardless of your deductible status. Some services provided during a visit may  incur additional costs."
	)
	public CopayNotSubjectToDeductibleIn: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, only after meeting your deductible. Some services provided during a visit may  incur additional costs."
	)
	public CoinsuranceSubjectToDeductibleIn: string;
	@T(
		"This is the percentage of costs you'll pay out-of-pocket for this service, regardless of your deductible status/accumulation. Some services provided during a visit may  incur additional costs."
	)
	public CoinsuranceNotSubjectToDeductibleIn: string;

	constructor(
		private router: Router,
		private trackingService: TrackingService,
		private changeDetector: ChangeDetectorRef,
		private telehealthService: TelehealthService,
		private _providersSearchService: ProvidersSearchService,
		private premiumFeatureService: PremiumFeatureService,
		private unleashService: UnleashService,
		private userService: UserService,
	) { }

	ngAfterViewChecked(): void {
		if (this.isMobile) this.setShowMoreTextPanel();
	}

	private setShowMoreTextPanel(): void {
		this.showMoreTextPanel = this.benefitDescription.nativeElement.offsetHeight >= TWO_LINES_HIGHT;
		this.changeDetector.detectChanges();
	}

	public onShowMoreText(): void {
		this.expended = !this.expended;
	}

	public onFindProvidersClicked(): void {
		this.trackingService.trackClientEvent('Immediate Care Choose option', { Type: this.immediateCareObj.title });
		if (this.immediateCareObj.index === 1) return this.telehealthService.onOpenTelehealth();

		const referrer = this.router.url;
		this._providersSearchService.goToProviderSearchWithValue(
			this.immediateCareObj.facilityType,
			SearchEntity.SearchByNeed,
			referrer
		);
	}

	public networkStructureSupportsAdditionalNetworks(benefit: Benefit) {
		if (!benefit || !benefit.networkStructure) return false;
		return networkStructureSupportsAdditionalNetworks(benefit.networkStructure);
	}

	public isInOrNoNetwork(additionalNetwork: AdditionalNetwork) {
		if (!additionalNetwork || !additionalNetwork.name) return false;
		return additionalNetwork.name.toLowerCase() === IN_NETWORK_NAME
			|| additionalNetwork.name.toLowerCase() === NO_NETWORK_NAME
	}
}
