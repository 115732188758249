<ion-header *ngIf="isIonic" class="ion-no-border">
	<div
		class="action-bar"
		[ngClass]="{
			'bottom-border': showBottomBorder,
			'android-action-bar': isAndroid
		}"
	>
		<!-- left-side 'back' navigation -->
		<span>
			<img
				*ngIf="ionicBackOverrideLink !== DISABLED_BACK_NAVIGATION"
				src="/assets/images/icons-ionic/arrow-narrow-left.svg"
				(click)="navigateBack()"
			/>
			<span *ngIf="ionicBackOverrideLink === DISABLED_BACK_NAVIGATION" class="logo-container">
			  <ng-container *ngIf="(appLogoUrl$ | async)?.isCustomPrimaryLogo; else fallback">
				  <img
					  class="logo-item--small"
					  [src]="(appLogoUrl$ | async).primarySm"
					  (error)="setDefaultImage()"
					  alt="Logo"
				  />

				  <img
					  class="logo-item logo-item--wide"
					  src="/assets/images/powered-by/powered-by-healthee-sm-horizontal.png"
					  alt="Logo"
				  />
			  </ng-container>

			  <ng-template #fallback>
				  <img
					  *ngIf="defaultImage"
					  class="logo-item"
					  src="/assets/images/logo-v2/logo.svg"
					  alt="Logo"
				  />
				  <img
					  *ngIf="!defaultImage"
					  class="logo-item"
					  [src]="(appLogoUrl$ | async).secondarySm"
					  (error)="setDefaultImage()"
					  alt="Logo"
				  />
				  <img
					  *ngIf="!defaultImage"
					  class="logo-item logo-item--wide"
					  src="/assets/images/powered-by/powered-by-healthee-sm-horizontal.png"
					  alt="Logo"
				  />
				  <div *ngIf="!defaultImage" class="logo-divider"></div>
				  <img
					  *ngIf="!defaultImage"
					  class="logo-item--medium"
					  [src]="(appLogoUrl$ | async).primarySm"
					  (error)="setDefaultImage()"
					  alt="Logo"
				  />
			  </ng-template>
			</span>

		</span>

		<!-- middle 'title' -->
		<span class="title"></span>

		<!-- right side 'notification-bell' icon -->
		<span
			class="bell-container"
		>

			<img
				(click)="uiService.navigate(['/mobile-app-settings'])"
				class="icon-size-24 cursor-pointer cypress-testing-selector-settings"
				src="/assets/icons/icon-settings.svg"
				alt=""
			/>
		</span>
	</div>
</ion-header>
