import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICON_SIZE } from 'ripple';
import { TelehealthService } from '../../../../modules/telehealth/services/telehealth.service';
import { TrackingService } from '../../../../services/tracking.service';
import {UserService} from "../../../../services/user.service";

@Component({
	selector: 'app-service-panel-teleheatlh-frame',
	templateUrl: './service-panel-telehealth-frame.component.html',
	styleUrls: ['./service-panel-telehealth-frame.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServicePanelTeleheatlhFrameComponent {
	public readonly ICON_SIZE = ICON_SIZE;
	public rippleSize: typeof ICON_SIZE = ICON_SIZE;
	public telehealthServiceFee$ = this.userService.getTelehealthServiceFee();

	@Input()
	subjectToDeductible: boolean;

	constructor(
		private telehealthService: TelehealthService,
		private trackingService: TrackingService,
		private userService: UserService,
	) {}

	public onTeaserClick(buttonName): void {
		this.telehealthService.onOpenTelehealth();
		this.trackingService.trackClientEvent('Benefits Telehealth modal start', { Action: buttonName });
	}
}
