import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../../services/user.service';
import { AuthService } from '../../../account/login/auth.service';

@Component({
	selector: 'debug-page',
	templateUrl: './debug.component.html',
	styleUrls: ['./debug.component.scss'],
})
export class DebugComponent implements OnInit{
	public userData$: Observable<any>;
	public email: string = null;
	public uid: string = null;

	public storage$: Observable<string>;

	constructor(
		private authService: AuthService,
	) {}

	ngOnInit() {
		this.authService.getUserDetails().then(d=> {
			d.forEach(i => {
				if (i.getName() === 'email') {
					this.email = i.getValue();
				} else if (i.getName() === 'custom:healthee_id'){
					this.uid = i.getValue();
				}
			});

			this.userData$ = of(d);
		}).catch(() => {
			console.log('DEBUG user not logged in')
		})


		const store = [];
		if (localStorage) {
			Object.keys(localStorage).forEach(function(key){
				store.push(key)
			});
		}

		if (sessionStorage) {
			Object.keys(sessionStorage).forEach(function(key){
				store.push(key)
			});
		}

		this.storage$ = of(store.join('\n'))
	}

	cleanup () {
		this.authService.signOut(false).then(() => {
			if (localStorage) {
				localStorage.clear();
			}
			if (sessionStorage) {
				sessionStorage.clear();
			}

			window.setTimeout(() => {
				window.location.reload();
			}, 500);
		})
	}
}
