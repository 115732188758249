import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ParamMap } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { User } from '../models/user.model';

import { OauthTokenGuard } from './oauth-token.guard';

import { UserService } from '../services/user.service';
import { UserSessionService } from '../services/userSession.service';
import { TrackingService } from '../services/tracking.service';
import { UIService } from '../services/ui.service';
import { PLAN_SELECTION_ONLY_PATH } from '../modules/main-layout/main-layout.constants';
import { DOCUMENT } from '@angular/common';
import { SessionStorageService } from '../services/sessionStorage.service';

enum LoginState {
	Authorized,
	Unauthorized,
}
@Injectable() // AppAuthGuard must be added as a provider in app.module.ts
export class AppAuthGuard {
	constructor(
		private router: Router,
		private oauthTokenGuard: OauthTokenGuard,
		private uiService: UIService,
		private userService: UserService,
		private userSessionService: UserSessionService,
		private trackingService: TrackingService,
		private sessionStorageService: SessionStorageService,
		@Inject(DOCUMENT) private document: any
	) {}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const domain = this.document.location.hostname;
		if (route.queryParamMap) this.saveUTMParamsToSessionStorage(route.queryParamMap);
		if (domain.startsWith(environment.cognito.clients['trinet'].appSubDomain) || this.uiService.useFederatedLogin) {
			const queryParamsToStore = ['returnUrl', 'eventDate', 'eventType', 'idp'];
			const queryParamsToClear = ['idp'];

			queryParamsToClear.forEach((key) => localStorage.removeItem(key));
			queryParamsToStore.forEach((key) => {
				const val = route.queryParamMap?.get(key);
				if (val) localStorage.setItem(key, val);
			});
		}

		return this.oauthTokenGuard.canActivate(route, state).pipe(
			take(1),
			switchMap(() => {
				const url = this.getUrlWithoutQueryParams(route['_routerState'].url);
				const queryParams = route.queryParams;
				return this.isCurrentUserAuthenticated(url, queryParams, route, state);
			})
		);
	}

	private saveUTMParamsToSessionStorage(queryParamsMap: ParamMap) {
		const utmParams = [];
		for (const key of queryParamsMap.keys) {
			if (key.toLowerCase().includes('utm_')) {
				utmParams.push({[key]: queryParamsMap.get(key)});
			}
			const utmParamsString = JSON.stringify(utmParams).replace(/[{}[\]",]/g, match => match === ',' ? ', ' : '');
			this.sessionStorageService.setItem('utmParams', utmParamsString);
		}
	}

	private isCurrentUserAuthenticated(
		url: string = null,
		queryParams: object = null,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> {
		return this.userService.user$.pipe(
			take(1),
			switchMap((currentUser: User) => {
				if (!currentUser || !currentUser.isAuthenticated) {
					return this.userService.attemptLogin(url, queryParams).pipe(
						tap((obj: BehaviorSubject<User>) => {
							if (obj && obj.subscribe) {
								obj.subscribe({
									next: (user) => {
										if (user) {
											this.trackingService.identifyUser(user.data).finally(() => {
												this.userSessionService.startUserSession('auto', user.data);
											});
											this.uiService.setPCTOnlyModeIfNeeded(!!user.data.company.PCTOnlyMode);
											if (this.uiService.PCTOnlyMode) {
												if (!state.url.startsWith(`/${PLAN_SELECTION_ONLY_PATH}`)) {
													this.router.navigate([`/${PLAN_SELECTION_ONLY_PATH}`]);
													return of(false);
												}
											}
										}
									},
								});
							} else if (obj && obj instanceof User) {
								this.trackingService.identifyUser(obj.data).finally(() => {
									this.userSessionService.startUserSession('auto', obj.data);
								});
							}
						})
					);
				}
				return of(currentUser);
			}),
			switchMap((currentUser: User) => {
				if (!currentUser) return of(LoginState.Unauthorized);

				return of(LoginState.Authorized);
			}),
			tap((loginState: LoginState) => {
				if (loginState === LoginState.Unauthorized) {
					this.router.navigate(['/account/login']);
				}
			}),
			switchMap((loginState: LoginState) => {
				if (loginState === LoginState.Unauthorized) {
					return of(false);
				}
				return of(true);
			})
		);
	}
	private getUrlWithoutQueryParams(urlString) {
		return urlString?.replace(/\?.*/, '');
	}
}
