<div class="service-panel" *ngIf="selectedService">
    <ng-container *ngIf="selectedService; else displayLoadingSpinner">
        <app-service-panel-header [shrinked]="'false'" [bgImage]="'https://assets.healthee.co/services_icons/'+selectedService?.iconPath"
            [title]="selectedService?.abbreviate" [serviceCoverage]="ServiceCoverage.covered_by_healthee"
            (closeClick)="closeClick.emit()"></app-service-panel-header>

        <div class="service-panel-details">
            <div class="coverage-info">
                <div class="title">
                    <div class="header">{{ 'Benefit Cost Breakdown' | translate }}</div>
                    <div class="sub-header">{{ 'How costs are split between you and your payer' | translate }}</div>
                </div>
                <div class="cost-info">
                    <ripple-icon [name]="'wallet'"></ripple-icon>
                    <ng-container *ngIf="telehealthServiceFee$ | async as telehealthServiceFee">
						<div class="description">
							<div class="cost">{{ 'You’ll pay ${tierPrice} per visit' | translate: {
								tierPrice: telehealthServiceFee.tierPrice,
							} }}</div>
							<div class="info">
								<span *ngIf="!telehealthServiceFee.tierPrice">{{ 'Applies always, regardless of your deductible' | translate }}</span>
								<span *ngIf="telehealthServiceFee.tierPrice">{{ 'until your deductible is met' | translate }}</span>
								<span [matTooltip]="'Your deductible is the amount youll have to pay out of pocket before your health plan kicks in and starts covering medical costs. Deductibles reset every year.' | translate">
								<ripple-icon [name]="'infoCircle'" [size]="rippleSize.size16"></ripple-icon>
							</span>
							</div>
						</div>
					</ng-container>
                </div>
            </div>

            <div class="service-content-info">
                <div class="header">
                    {{ 'Important to know' | translate }}
                </div>
                <div *ngIf="telehealthServiceFee$ | async as telehealthServiceFee" class="sub-header">
					{{ telehealthServiceFee.tierPrice ? selectedService?.paidDesc : selectedService?.desc }}
				</div>
            </div>


            <div *ngIf="relatedServices?.length > 0" class="service-content-benefits">
                <div class="service-content-benefits-title">{{ 'Related Benefits' | translate }}</div>

                <div class="service-content-benefits-wrapper">
                    <div class="related-benefit" (click)="relatedBenefitClick.emit(service?._id)"
                        [appClientClickEventTracker]="{
							event: 'Coverage Info Related Benefits click',
							metaData: {
								'service name': selectedService?.abbreviate,
								relatedServiceName: service?.abbreviate
							}
						}" *ngFor="let service of relatedServices; let i = index">
                        <img class="related-benefit-image" [src]="service?.iconPath | serviceImage : i" />
                        <div class="related-benefit-content">
                            <span class="related-benefit-title">{{ service?.abbreviate }}</span>
                            <span *ngIf="service?._id === telehealthSerivcesIDFromDB; else coveredByHealthee">
                                <span
									class="coverage-badge covered"
                                	*ngIf="(userBenefitsMap$ | async)?.get(service._id)?.covered === 'covered'"
								>
									{{ 'Covered' | translate }}
								</span>

                                <span
									class="coverage-badge not-covered"
                                	*ngIf="(userBenefitsMap$ | async)?.get(service._id)?.covered !== 'covered'"
								>
									{{ 'Not Covered' | translate }}
								</span>
                            </span>

                            <ng-template #coveredByHealthee>
                                <span class="coverage-badge covered-by-healthee">{{ 'Covered by Healthee' | translate }}</span>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="feedback-wrapper">
                <app-feedback-block [feature]="'benefit panel'"
                    [data]="{ 'benefit ID': selectedService?._id, 'service': selectedService.name }"></app-feedback-block>
            </div>
        </div>
    </ng-container>

    <app-service-panel-footer [displayConnectNow]="'true'"
        (connectNowClicked)="openTelehealth()"></app-service-panel-footer>
</div>


<ng-template #displayLoadingSpinner>
    <div class="service-panel-loading">
        <app-loader size="medium"></app-loader>
    </div>
</ng-template>
